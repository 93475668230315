import logoIcon from '../assets/rockit-logo.png';

const DesktopLogo = ({ logoRef }) => {
	return (
		<div className='fixed pl-8 xl:pl-12 xxl:pl-[60px] px-2 py-[14px] mt-[30px]'>
			<div ref={logoRef} className='h-[47px] w-fit select-none z-20 overflow-hidden'>
				<img src={logoIcon} alt='logo' className='h-full' />
			</div>
		</div>
	);
};

export default DesktopLogo;
