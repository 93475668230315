import { classNames } from '../../utils/classNames';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useAppContext } from '../../context/AppContextProvider';

import FormComponent from '../form/FormComponent';
import background from '../../assets/hero/welcome-gradient.webp';
import backgroundMobile from '../../assets/hero/welcome-gradient-mobile.webp';
import Navigation from '../navigation/Navigation';

const CustomizeExperienceCard = () => {
	const {
		setMobileNavigationOpen,
		navigationPath,
		contentList,
		setContentList,
		availableSectionsList,
		setAvailableSectionsList,
		setSectionLoading,
	} = useAppContext();

	const isMobile = useMediaQuery('(max-width: 575px)');

	return (
		<div
			className={classNames(
				'relative flex items-center',
				'px-2 sm:px-4 md:px-8 lg:px-12 xl:px-20 py-4 sm:py-16 xl:py-[100px] mx-auto rounded-[32px]',
				// 'sm:min-h-[600px] xl:min-h-[650px] h-full',
				'transition duration-300 ease-in-out z-20 bg-white overflow-hidden',
				'max-w-[876px] lg:max-w-[clamp(600px,60%,876px)] xl:max-w-[clamp(650px,65%,876px)] xxl:max-w-[clamp(700px,80%,876px)] w-full'
			)}
		>
			<img
				src={isMobile ? backgroundMobile : background}
				alt='decoration'
				className={classNames(
					'w-full absolute h-full z-[19]',
					'left-1/2 -translate-x-1/2',
					'bottom-0 translate-y-[5%] sm:translate-y-[25%] xl:translate-y-[30%]'
				)}
			/>

			<div className='flex-1 flex flex-col items-center justify-between gap-4 sm:gap-8'>
				<h1
					className={classNames(
						'whitespace-nowrap text-[#2597DC] font-extrabold mt-8 sm:mt-0',
						'text-[35px] xs:text-[45px] xxs:text-[55px] sm:text-[60px] lg:text-[65px] xl:text-[75px] xxl:text-[89px]',
						'leading-[47px] xs:leading-[60px] sm:leading-[75px] xl:leading-[89px]'
					)}
				>
					We do IT better!
				</h1>

				<p
					className={classNames(
						'max-w-[405px] w-full text-center',
						'text-[12px] sm:text-[14px] lg:text-[16px]',
						'leading-[20px] md:leading-[30px] z-20'
					)}
				>
					We are a Leading Provider of{' '}
					<span className='font-semibold'>
						Excellent <br /> IT Solutions
					</span>{' '}
					and Services.
				</p>

				<p
					className={classNames(
						'max-w-[275px] sm:max-w-none w-full z-[21]',
						'text-[20px] sm:text-[24px] md:text-[26px] xxl:text-[30px]',
						'leading-[26px] sm:leading-[24px] xxl:leading-[28px]',
						'font-medium text-center text-white mt-4 mb-3'
					)}
				>
					Continue exploring <br className='xs:hidden' /> or ask your question
				</p>

				<FormComponent mode='card' />

				<div className={classNames(isMobile ? '' : 'hidden', 'max-w-[315px] w-full mx-auto py-6 z-[100]')}>
					<Navigation
						mode='mobile'
						setMobileNavigationOpen={setMobileNavigationOpen}
						className='w-full grid grid-cols-4 gap-x-[10.25px] gap-y-10'
						navigationPath={navigationPath}
						contentList={contentList}
						setContentList={setContentList}
						availableSectionsList={availableSectionsList}
						setAvailableSectionsList={setAvailableSectionsList}
						setSectionLoading={setSectionLoading}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomizeExperienceCard;
