import logoIcon from '../assets/rockit-logo.png';
import { classNames } from '../utils/classNames';

const MobileLogo = ({ logoRef, className }) => {
	return (
		<div ref={logoRef} className={classNames('z-20 select-none overflow-hidden', className)}>
			<img src={logoIcon} alt='logo' className='h-full' />
		</div>
	);
};

export default MobileLogo;
