import { useState } from 'react';
import { getArticles } from '../../axios/API';
import { classNames } from '../../utils/classNames';
import { highlightMatch } from '../../utils/highlightMatch';

import RequestHeading from './RequestHeading';
import searchIcon from '../../assets/request-record/search.svg';

import '../../styles/custom-scrollbar.scss';

const RequestRecord = ({
	sectionRefs,
	requestRecord,
	setContentList,
	addUserQueryMessage,
	handleChangeSectionRefs,
	setFormLoading,
	setIsRequestPopupOpen,
}) => {
	const [searchQuery, setSearchQuery] = useState('');

	const filteredRequests = requestRecord.filter((request) => request.toLowerCase().includes(searchQuery.toLowerCase()));

	const handleScrollToRequest = async (request) => {
		const currentRequestSection = sectionRefs.find(({ section }) => section === request)?.ref;

		if (currentRequestSection && currentRequestSection.current) {
			setIsRequestPopupOpen(false);

			currentRequestSection.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		} else {
			setFormLoading(true);
			addUserQueryMessage(request);
			setIsRequestPopupOpen(false);
			const sectionRef = handleChangeSectionRefs(request);

			try {
				const { data } = await getArticles(request);

				setContentList((prev) => [
					...prev,
					{
						type: 'form-response',
						sectionRef: sectionRef,

						// sectionName: NAVIGATION.find((element) => element.value === value).label,
						// sectionId: NAVIGATION.find((element) => element.value === value).value,

						items: data.block.items,
						questions: data.questions,

						// nextPage: pageParam + 1,
						// hasMore: availableSectionsList.length > 1,
					},
				]);
			} catch (error) {
				console.error(error);
			} finally {
				setFormLoading(false);
				setTimeout(() => {
					if (sectionRef.current) {
						sectionRef.current.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
						});
					}
				}, 100);
			}
		}
	};

	return (
		<div
			className={classNames(
				'w-full max-h-[385px] h-full',
				'flex flex-col gap-[11px]',
				'p-[22px] rounded-xl text-black/60',
				'shadow-cardShadow bg-backgroundColor-secondary'
			)}
		>
			<RequestHeading />

			<div className='w-full relative'>
				<div className='absolute top-1/2 -translate-y-1/2 left-[10px]'>
					<img src={searchIcon} alt='search icon' />
				</div>

				<input
					type='text'
					value={searchQuery}
					onChange={(event) => setSearchQuery(event.target.value)}
					className={classNames(
						'h-[34px] w-full pl-7 py-[5px] pr-2',
						'rounded-[18px] border border-transparent',
						'focus:outline-none focus:border-[#2597DC]',
						'transition-colors duration-300 text-xs',
						'bg-backgroundColor-primary placeholder:text-xs'
					)}
				/>
			</div>

			<ul className='h-[238px] flex flex-col gap-[11px] pr-3 overflow-y-auto scrollbar-custom'>
				{filteredRequests.length > 0 &&
					filteredRequests.map((request, index) => (
						<li
							key={index}
							className='w-full font-medium text-xs break-words cursor-pointer'
							onClick={() => handleScrollToRequest(request)}
						>
							{highlightMatch(searchQuery, request)}
						</li>
					))}
			</ul>

			<div className='flex justify-end'>
				<button onClick={() => setIsRequestPopupOpen(false)} className='font-semibold text-[11px] leading-[15px]'>
					Close
				</button>
			</div>
		</div>
	);
};

export default RequestRecord;
