import { classNames } from '../../utils/classNames';
import { ReactComponent as ChevronIcon } from '../../assets/templates-cards/chevron-icon.svg';

const ExpandButton = ({ header, sectionName, isExpanded, toggleCard }) => {
	return (
		<button
			onClick={() => toggleCard(header)}
			aria-label={isExpanded ? 'Collapse card' : 'Expand card'}
			className={classNames(!sectionName ? 'ml-auto' : '')}
		>
			<ChevronIcon
				alt={isExpanded ? 'Collapse icon' : 'Expand icon'}
				className={classNames(isExpanded ? 'rotate-180' : '', 'h-[30px] w-[30px]', 'fill-[#D4EFFF]')}
			/>
		</button>
	);
};

export default ExpandButton;
