import { useRef, createRef, useState } from 'react';
import { classNames } from '../../utils/classNames';
import { removeMarkdown } from '../../utils/removeMarkdown';

import TemplateCardWrapper from './TemplateCardWrapper';
import RelatedQuestions from '../questions/RelatedQuestions';
import Contents from '../navigation/Contents';
import '../../styles/masonry.scss';

const ResponseWrapper = ({ page, pageIndex, sectionLoading }) => {
	const [activeContentItems, setContentActiveItems] = useState([]);

	const validateArray = (array) => Array.isArray(array) && array.length > 0;

	const validateEntity = (entities) => {
		return Array.isArray(entities)
			? entities.every((entity) => typeof entity === 'object' && entity !== null)
			: !!entities && typeof entities === 'object';
	};

	const contentList = validateArray(page.items)
		? page.items.map(({ header }, index) => ({
				id: index + 1,
				content: removeMarkdown(header),
		  }))
		: [];

	const cardRefs = useRef(contentList.map(() => createRef()));

	const contentWithRefs = contentList.map((item, index) => ({
		...item,
		ref: cardRefs.current[index],
	}));

	return (
		<div className={classNames('relative m-auto  w-full')}>
			<div
				className={classNames(
					'hidden lg:flex h-[calc(100%-48px)]',
					'absolute right-14 xl:right-4 xxl:right-2',
					'max-w-[150px] xl:max-w-[200px] xxl:max-w-[250px] w-full'
				)}
			>
				{validateArray(page.items) && (
					<Contents pageIndex={pageIndex} contentList={contentWithRefs} activeContentItems={activeContentItems} />
				)}
			</div>

			<div
				className={classNames(
					'relative m-auto',
					'max-w-[820px] w-full',
					'lg:max-w-[clamp(550px,55%,820px)]',
					'xl:max-w-[clamp(600px,60%,820px)]',
					'xxl:max-w-[clamp(600px,70%,820px)]'
				)}
			>
				{page.sectionName !== null && page.sectionName !== undefined && (
					<h3 className='ml-[18px] text-[#2597DC] text-[26px] leading-[36px] md:text-[32px] md:leading-[44px] font-bold mb-6'>
						{page.sectionName === 'Technologies' ? 'Tech' : page.sectionName}
					</h3>
				)}

				{validateEntity(page.items[0]) && (
					<TemplateCardWrapper
						ref={cardRefs.current[0]}
						image={page.items[0].img}
						header={page.items[0].header}
						text={page.items[0].text}
						maxWidth='max-w-[820px]'
						isFirst={true}
						setContentActiveItems={setContentActiveItems}
						sectionName={page.sectionName}
					/>
				)}

				{validateArray(page.items) && validateEntity(page.items) && page.items.length > 1 && (
					<div className='mt-[22px] md:mt-6 masonry'>
						{page.items.slice(1).map(({ img: image, header, text }, index) => {
							const isLast = index === page.items.slice(1).length - 1;

							return (
								<TemplateCardWrapper
									key={`${header}-${index}`}
									image={image}
									header={header}
									ref={cardRefs.current[index + 1]}
									text={text}
									index={index}
									isFirst={false}
									isLast={isLast}
									setContentActiveItems={setContentActiveItems}
									sectionName={page.sectionName}
								/>
							);
						})}
					</div>
				)}

				{validateArray(page.questions) && (
					<RelatedQuestions questions={page.questions} sectionLoading={sectionLoading} />
				)}
			</div>
		</div>
	);
};

export default ResponseWrapper;
