import { classNames } from '../../utils/classNames';
import { ReactComponent as MenuIcon } from '../../assets/request-record/menu-gray.svg';

const RequestHeading = ({ mode, handleClick }) => {
	return (
		<div
			onClick={handleClick}
			className={classNames(
				'flex items-center cursor-pointer',
				'transition-all duration-300 ease-in-out',
				mode === 'sidebar' ? 'group gap-[10px]' : 'gap-1'
			)}
		>
			<MenuIcon
				className={classNames(
					mode === 'sidebar' ? 'h-[10px] w-[14px]' : 'h-2',
					'transition-all duration-300 ease-in-out',
					'fill-black group-hover:fill-[#2597DC]'
				)}
			/>

			<p
				className={classNames(
					mode === 'sidebar' ? 'text-[16px] leading-[22px]' : 'text-[13px] leading-[18px]',
					'font-bold transition-all duration-300 ease-in-out group-hover:text-[#2597DC]'
				)}
			>
				Request Record
			</p>
		</div>
	);
};

export default RequestHeading;
