import { classNames } from '../../utils/classNames';
import { removeMarkdown } from '../../utils/removeMarkdown';

import Popup from './Popup';
import Markdown from '../template-cards/Markdown';
import { ReactComponent as CloseIcon } from '../../assets/navigation/close.svg';

import '../../styles/custom-scrollbar.scss';

const CardPopup = ({ isOpen, handleClose, cardContent, className, zIndex }) => {
	return (
		<Popup isOpen={isOpen} handleClose={handleClose} className={className} zIndex={zIndex}>
			<div className='relative p-[18px] pb-[26px] rounded-[28px] shadow-cardShadow bg-backgroundColor-secondary'>
				<button className='absolute top-8 right-11' aria-label='Close' onClick={handleClose}>
					<CloseIcon className='fill-white' />
				</button>

				<div
					className={classNames(
						'w-full h-full max-h-[calc(100vh-80px)]',
						'flex flex-col gap-[22px] overflow-y-auto',
						'overflow-y-auto bg-backgroundColor-secondary',
						'transition-height duration-300 ease-in-out z-[80]',
						'pr-1.5 rounded-tl-[20px] rounded-tr-[20px] rounded-bl-[20px] scrollbar-custom'
					)}
				>
					<img src={cardContent.image} alt='' className='rounded-[16px] max-h-[259px] h-full' />

					<h3 className='text-[#2597DC] font-bold text-[26px] leading-[36px]'>{removeMarkdown(cardContent.header)}</h3>

					<span className='font-medium text-sm leading-[25px] whitespace-normal'>
						<Markdown displayedText={cardContent.text} />
					</span>

					<div className='flex items-center justify-end'>
						<button
							onClick={handleClose}
							className='w-[107px] h-[30px] text-xs text-[#2597DC] font-medium rounded-[30px] bg-[#D4EFFF]'
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default CardPopup;
